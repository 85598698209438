<template>
  <div>
      <h1>Dis is forgot password page</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>